@import '../shared/common.scss';
// @import "@fontsource/roboto";
$font: 'Roboto';

.session_detail_container {
    width: 100%;
    display: inline-block;
    padding: 8px;
    padding-left: 0px;
    box-sizing: border-box;

    .session_detail {
        width: 100%;
        text-align: left;
        word-break: break-word;
        font-family: $font;
        letter-spacing: 0.2px;
        color: #424242;

        &.title {
            display: block;
            font-weight: 700;
            font-size: 14px;
            padding-bottom: 4px;
            line-height: 16px;

        }

        &.description {
            display: inline-block;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            overflow: hidden;
            -webkit-box-orient: vertical;

        }

    }


}

.sc_m_session_content {
    display: inline-flex;
    width: 100%;
    padding: 0px 16px;
    padding-top: 16px;
    box-sizing: border-box;

    .sc_m_session_left {
        display: inline-flex;
        height: 24px;
        width: 24px;
        align-items: center;
        flex-flow: column;

        .sc_m_session_count_container {
            width: 100%;
            height: 100%;
            display: inline-flex;
            background-color: white;
            border-radius: 100%;
            height: 100%;
            // padding: 0px 4px;

            &.ser_col {
                border-radius: 100%;
                height: 100%;
            }

            .sc_m_session_count {
                width: 40px;
                height: 24px;
                display: inline-flex;
                font-family: $font;
                letter-spacing: 0.2px;
                font-weight: 700;
                font-size: 10px;
                // line-height: 12px;
                text-align: center;
                color: #424242;
                align-items: center;
                justify-content: center;
                vertical-align: middle;
            }
        }
    }
}

.collection_audio_processing_container {
    border-radius: 6px;
    width: fit-content;
    max-width: 178px;
    height: 28px;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 4px 6px;
    box-sizing: border-box;
    margin: 0px 0px 8px 12px;

    .audio_processing_icn {
        content: url('../../images/mindfulness/audio_processing_icn.svg');
        width: 20px;
        height: 20px;
    }

    .draft_processing_icn {
        width: 16px;
        height: 100%;

        &::before {
            content: '\e963';
            font-family: icomoon;
            font-size: 12px;
            color: #0070D2;
        }

    }

    .audio_processing_txt {
        margin-left: 6px;
        font-family: $font;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: #706E6B;
        text-align: left;

        &.draft {
            margin-left: 2px;
        }
    }

    .audio_draft_button {
        width: 168px;
        border-radius: 10px;
        background: #008AFF;
        border: none;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        cursor: pointer;
        padding: 10px 24px;
        box-sizing: border-box;
        outline: none;
        text-decoration: none;
    }
}

// responsive

@media screen and (min-width: 1021px) {
    .session_wrapper {
        &.with_desc {
            width: 100%;
        }
    }

}