@import './_common.scss';

p {
    margin: 0px;
}

.content_header {
    display: flex;
    height: 76px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    justify-content: space-between;
    align-items: center;
    background-color: $color_1;
    color: $color_2;
    letter-spacing: 0.2px;

    &.login,
    &.sign-up {
        background: transparent;
        height: 95px;
        position: relative;
    }
}

.content_header_container {
    display: flex;
    width: 100%;
    padding: 18px;
    box-sizing: border-box;
    max-width: 1280px;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    &.login,
    &.sign-up {
        height: 100%;
    }
}

.content_header_logo {
    display: flex;
    flex-grow: 1;
}

.content_header_logo img {
    height: 28px;
    cursor: pointer;
}

.content_header_logo {

    &.login,
    &.sign-up {
        img {
            height: 36px !important;
        }
    }
}

.content_header_nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.content_header_list {
    display: inline-block;
    margin-left: 12px;
    padding: 4px 12px;
    box-sizing: border-box;

    &.login,
    &.sign-up {
        &:hover {
            border-bottom: 2px solid $color_1;

            .content_header_nav_menu {
                color: $color_1;
            }
        }

        &.selected {
            border-bottom: 2px solid $color_1;
        }
    }

    &:hover {
        border-bottom: 2px solid $color_3;

        .content_header_nav_menu {
            color: $color_3;
            font-weight: 600;
        }
    }

    &.selected {
        border-bottom: 2px solid $color_3;
    }

}

.content_header_list:first-child {
    margin-left: 0;
}

.content_header_nav_menu {
    color: $color_2;
    font-family: $font;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;

    &.login,
    &.sign-up {
        color: $color_1;
        font-size: 18px;
        line-height: 20px;

        &.selected {
            color: $color_1;
        }
    }

    &.selected {
        font-weight: 600;
        color: $color_3;
    }

}

.content_header_list_li {
    display: inline-block;
    margin-left: 12px;
}

.try_alaia {
    width: 116px !important;
    height: 40px !important;
    border-radius: 32px;
    background: linear-gradient(to right, #911AA0, #003889);
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
    outline: none;

    &.home {
        width: 100% !important;
        height: 50px !important;
    }

    &.tab_view {
        display: none;
    }
}

.responsive_content_header_nav {
    display: none;
}

.content_header_resposive_option {
    display: none;
}

@media screen and (max-width:1240px) {
    .content_header_nav {
        display: none;
    }

    .content_header {
        height: 86px;
    }

    .responsive_content_header_nav {
        display: block;
        width: 100%;
        top: 86px;
        left: 0;
        position: fixed;
        background-color: $color_1;
        overflow-x: hidden;
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        height: 100%;
        overflow-y: scroll;
        max-height: -moz-available;
        /* WebKit-based browsers will ignore this. */
        max-height: -webkit-fill-available;
        /* Mozilla-based browsers will ignore this. */
        max-height: fill-available;
        z-index: 5;

        &.show {
            opacity: 1;
            transform: translateY(0);

        }

    }

    .responsive_content_header_nav_container {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
        padding: 28px 42px 0px 42px;
        box-sizing: border-box;
    }

    .content_header_container {
        height: 86px;
        padding: 24px 20px;
        box-sizing: border-box;

        &.login,
        &.sign-up {
            &.show {
                background-color: $color_1;
            }
        }
    }

    .content_header_logo img {
        height: 28px;
    }

    .content_header_logo {

        &.login,
        &.sign-up {
            img {
                height: 36px !important;
            }
        }
    }

    .content_header_list_responsive {
        width: 100%;
        height: 34px;
        margin-bottom: 16px;

    }

    .content_header_nav_menu,
    .content_header_nav_menu.login,
    .content_header_nav_menu.sign-up {
        font-size: 28px;
        line-height: 34px;
        font-weight: 500;
        color: #606060;
    }

    .content_header_resposive_option {

        &::before {
            display: inline-block;
            font-family: 'icomoon';
            color: #424242;
            font-size: 24px;
        }

        &.login,
        &.sign-up {
            &::before {
                color: $color_1;
            }

            &.show {
                &::before {
                    color: #424242;
                }
            }
        }

        &.show {
            &::before {
                content: '\e949';
            }
        }

        &.hide {
            &::before {
                content: '\e971';
            }
        }

        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 28px;
        box-sizing: border-box;
        cursor: pointer;
    }

    .bottom_container {
        width: 100%;
        // height: calc(100% - 262px);
        height: calc(100% - 112px);
        display: flex;
        flex-wrap: wrap;
        align-items: end;
    }

    .try_alaia_container {
        width: 100%;
        height: 156px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .try_alaia_container_button {
            width: 290px;
            height: 52px;

            .try_alaia {
                width: 100% !important;
                height: 100% !important;
            }
        }

        .try_alaia_container_text {
            text-align: center;
            font-weight: 400;
            font-size: 14px;
            color: $color_5;
            line-height: 16px;
            margin-top: 40px;
            margin-bottom: 28px;
        }
    }

    .content_header_responsive_slider_container {
        display: flex;
        width: 100%;
        height: 22px;
        align-items: center;
        justify-content: center;
        text-align: center;

        .content_header_responsive_slider {
            width: 140px;
            height: 5px;
            border-radius: 100px;
            display: block;
            rotate: 180deg;
            border: 1px solid $color_2;
            background-color: $color_2;
        }
    }

    .try_alaia {
        &.tab_view {
            display: inline-block;
        }
    }
}

@media screen and (max-width:740px) {

    .content_header_nav {
        display: none;
    }

    .content_header {
        height: 64px;
    }

    .try_alaia {
        &.tab_view {
            display: none;
        }
    }

    .content_header_container {
        height: 64px;
        padding: 12px 16px 24px 16px;
        box-sizing: border-box;
    }

    .responsive_content_header_nav {
        top: 64px;
    }
}