$font: 'Roboto';

@font-face {
    src: url('../../fonts/icomoon.woff') format('woff'),
        url('../../fonts/icomoon.ttf') format('truetype');
    font-style: normal;

    font: {
        family: 'icomoon';
        weight: normal;
    }
}

.toaster_wrapper {
    display: inline-block;
    z-index: 3;
    position: fixed;
    top: 62px;
    right: 24px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 3px 0px #00000033;
    box-shadow: 0px 2px 2px 0px #0000001F;
    box-shadow: 0px 0px 2px 0px #00000024;
    padding: 24px 16px;
    box-sizing: border-box;
    min-width: 332px;

    &.toaster_wrapper {
        max-width: 332px;
        padding: 16px 24px;
        top: 92px;

        &.feedback {
            margin-top: 0px;
        }
    }

    &:nth-of-type(1) {
        margin-top: 0px;
    }

    &:nth-of-type(2) {
        margin-top: 158px;
    }

    .toaster_close_button_container {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-left: -28px;
        margin-top: -14px;

        .close_button {
            cursor: pointer;

            &::before {
                content: '\e923';
                display: inline-block;
                font-family: 'icomoon';
                color: #706E6B;
                font-size: 10px;
            }
        }

    }

    .toaster_container {
        .toaster_message_container {
            display: flex;

            .toaster_message_left {
                .icn_container {
                    width: 42px;
                    height: 42px;
                    margin-right: 10px;

                    &.queue {
                        content: url('../../images/audio_generated_icn.svg');
                    }

                    &.rate_limit {
                        content: url('../../images/custom_exercise_limit_icn.svg');
                    }

                    &.feedback {
                        content: url('../../images/feedback_green.svg');
                    }

                    &.feedback_action {
                        content: url('../../images/feedback_action.svg');
                    }
                }
            }

            .toaster_message_right {
                .toaster_message_header {
                    font-family: $font;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16px;
                    text-align: left;
                    color: #080707;
                    margin: 0px;
                    margin-bottom: 8px;
                }

                .toaster_message_subheader {
                    font-family: $font;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14px;
                    text-align: left;
                    color: #706E6B;
                    margin: 0px;
                }
            }
        }

        .toaster_message_container:has(+ .toaster_button_container) {
            margin-bottom: 12px;
        }

        .toaster_button_container {
            .toaster_button {
                display: flex;
                align-items: center;
                justify-content: center;
                outline: none;
                width: 100%;
                height: 32px;
                background-color: #fff;
                color: #0070D2;
                border: 1px solid #0070D2;
                font-family: $font;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }
}