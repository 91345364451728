$font: 'Roboto';
$color_1: #fff;
$color_2: #000;
$color_3: #0C6FF9;
$color_4: #44423F;
$color_5: #413D45;
$text_color_1: #424242;
$text_color_2: #fff;

@font-face {
    src: url('../../fonts/icomoon.woff') format('woff'),
        url('../../fonts/icomoon.ttf') format('truetype');
    font-style: normal;

    font: {
        family: 'icomoon';
        weight: normal;
    }
}

@mixin text-styles($font-size, $font-family, $font-weight, $color, $line-height, $margin-top: 0, $margin-bottom: 0) {
    font-size: $font-size;
    font-family: $font-family;
    font-weight: $font-weight;
    color: $color;
    line-height: $line-height;
    margin-top: $margin-top;
    margin-bottom: $margin-bottom;
}

@mixin slider-text-styles {
    font-size: 16px;
    letter-spacing: 0.2px;
    font-family: $font;
    font-weight: 400;
    color: $color_5;
    line-height: 26px;
    text-align: left;
    white-space: pre-wrap;
    margin-bottom: 22px;
}

@mixin slider-button-styles {
    width: 134px !important;
    height: 38px !important;
    border-radius: 32px;
    background: linear-gradient(to right, #911AA0, #003889);
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
    outline: none;
}

@mixin flex-common-style($display, $align-items, $justify-content, $flex-direction) {
    display: $display;
    align-items: $align-items;
    justify-content: $justify-content;
    flex-direction: $flex-direction;
}

@mixin background-image-style($background-size, $background-repeat, $background-position) {
    background-size: $background-size;
    background-repeat: $background-repeat;
    background-position: $background-position;
}

.content_section_wrapper_main {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.content_section_container {
    display: inline-block;
    width: 100%;
    max-width: 1440px;
}

.content_home_wrapper {
    width: 100%;
    cursor: default;

    &.article {
        margin-top: 16px
    }
}

.banner_container {
    @include flex-common-style(flex, center, center, unset);
    width: 100%;
    height: 400px;
    position: absolute;
    top: 76px;
    left: 0;
    z-index: 1;
    text-align: center;
    background: linear-gradient(to right, rgba(0, 6, 138, 0.1), rgba(0, 0, 0, 0.3));
    flex-wrap: wrap;
}

.content_wellness,
.content_learn {
    .banner_container {
        background: linear-gradient(to right, rgba(0, 6, 138, 0.1), rgba(0, 0, 0, 0.3));
    }
}

.banner_video,
.banner_thumbnail {
    display: inline-block;
    width: 100%;
    height: 400px;
    object-fit: cover;

    &.learn {
        height: 100%;
        border-radius: 38px;
    }
}

.content_section_1_logo {
    width: 200px;
    height: 65px;
    display: flex;

    img {
        height: 55px;
    }

    &.learn,
    &.wellness {
        width: 304px;
        height: 65px;

        img {
            margin-top: 10px;
        }
    }

    &.about_us,
    &.impact {
        align-items: flex-end;
    }
}

.content_section_1_text {
    display: inline-block;
    margin-left: 8px;
    letter-spacing: 2px;
    height: 100%;
    @include text-styles(26px, $font, 700, $color_1, 42px);
}

.banner_content_container {
    width: 100%;
    @include flex-common-style(inline-flex, center, center, column);
}

.content_section_1 {
    @include text-styles(24px, $font, 400, $color_1, 28px, 28px, 12px);

    &.learn {
        @include text-styles(36px, $font, 500, $color_1, 42px, 24px);
    }

    &.wellness {
        @include text-styles(36px, $font, 500, $color_1, 42px, 24px, 28px);
    }

    &.about_us,
    &.impact {
        @include text-styles(36px, $font, 500, $color_1, 42px, 0px, 8px);
    }
}

.banner_text {
    @include text-styles(20px, $font, 400, $color_1, 24px);
}

.banner_button {
    display: inline-flex;
    border: none;
    outline: none;
    align-items: center;
    padding: 10px 26px;
    box-sizing: border-box;
    border-radius: 32px;
    border: 2px solid $color_1;
    background-color: transparent;
    cursor: pointer;
    @include text-styles(14px, $font, 700, $color_1, 18px);

    &::before {
        content: "\e913";
        font-family: "icomoon";
        height: 100%;
        color: $color_1;
        font-size: 14px;
        margin-right: 8px;
    }
}

.responsive_banner_text_container {
    display: none
}

.responsive_gradient {
    display: none;
}

.lazyload-wrapper {
    width: 100%;
}

.alaia_button_container {
    .alaia_button {
        border-radius: 32px;
        background: linear-gradient(to right, #911AA0, #003889);
        border: none;
        color: white;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        text-align: center;
        cursor: pointer;
        outline: none;

        &.about_us_section_2 {
            width: fit-content;
            padding: 10px 26px;
            box-sizing: border-box;
        }
    }
}

@media screen and (max-width: 1240px) {

    .content_home_wrapper {

        &.article {
            margin-top: 24px
        }
    }

    .banner_container {
        top: 86px;
    }

    .content_wellness,
    .content_learn,
    .content_about_us,
    .content_impact {
        .banner_content_container {
            display: inline-flex;
        }

        .banner_container {
            display: flex;
            align-items: center;
            justify-content: center;
            top: 86px;
            height: 344px !important;
        }

        .content_section_1_logo {

            &.wellness,
            &.learn,
            &.about_us,
            &.impact {
                display: block;

                img {
                    width: 200px;
                    height: 56px;
                    margin-top: 0px;
                    margin-bottom: 8px;
                }
            }
        }
    }

    .content_section_1_logo {

        &.wellness,
        &.learn,
        &.about_us,
        &.impact {
            display: block;

            img {
                width: 200px;
                height: 56px;
                margin-top: 0px;
                margin-bottom: 8px;
            }
        }
    }

    .content_section_1_text {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        margin: 0px;
        margin-bottom: 20px;
        height: 24px;
        width: 100%;
    }

    .content_section_1 {

        &.wellness,
        &.learn {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            margin: 0px;
            margin-top: 40px;
            margin-bottom: 24px;
        }

        &.about_us {
            font-size: 36px;
            font-weight: 500;
            line-height: 42px;
            margin-bottom: 18px;
        }

        &.impact {
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 18px;
        }
    }

    .alaia_button_container {
        &.about_us_section_2 {
            margin-bottom: 14px;
        }
    }
}


@media screen and (max-width:740px) {
    .content_home_wrapper {

        &.article {
            margin-top: 12px
        }
    }

    .banner_container {
        top: 368px;
        display: block;
        height: 302px;
        background: linear-gradient(to right, rgba(0, 6, 138, 0.1), rgba(0, 0, 0, 0.3));
        align-items: flex-start;

        .responsive_gradient {
            display: block;
            width: 100%;
            height: 24px;
            background: linear-gradient(to bottom, rgba(28, 28, 30, 1), rgba(48, 48, 48, 0));
        }
    }

    .banner_content_container {
        display: none;
    }

    .content_wellness,
    .content_learn,
    .content_about_us,
    .content_impact {
        .banner_content_container {
            display: inline-flex;
        }

        .banner_container {
            top: 64px;

            &.about_us {
                height: 220px !important;
            }
        }
    }

    // .content_about_us,
    // .content_impact {
    //     .banner_container {
    //         height: 220px !important;
    //     }

    // }

    // .content_wellness,
    // .content_learn,
    // .content_about_us,
    // .content_impact {
    //     .banner_content_container {
    //         display: inline-flex;
    //     }

    //     .banner_container {
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         top: 64px;
    //         height: 344px !important;
    //     }

    //     .content_section_1_logo {

    //         &.wellness,
    //         &.learn,
    //         &.about_us,
    //         &.impact {
    //             display: block;

    //             img {
    //                 width: 200px;
    //                 height: 56px;
    //                 margin-top: 0px;
    //                 margin-bottom: 8px;
    //             }
    //         }
    //     }

    //     .content_section_1_text {
    //         font-size: 20px;
    //         font-weight: 400;
    //         line-height: 24px;
    //         margin: 0px;
    //         margin-bottom: 20px;
    //         height: 24px;
    //         width: 100%;
    //     }

    //     .content_section_1 {

    //         &.wellness,
    //         &.learn {
    //             font-size: 20px;
    //             font-weight: 500;
    //             line-height: 28px;
    //             margin: 0px;
    //             margin-top: 40px;
    //             margin-bottom: 24px;
    //         }

    //         &.about_us {
    //             font-size: 36px;
    //             font-weight: 500;
    //             line-height: 42px;
    //             margin-bottom: 18px;
    //         }

    //         &.impact {
    //             font-size: 20px;
    //             font-weight: 400;
    //             line-height: 24px;
    //             margin-bottom: 18px;
    //         }
    //     }
    // }

    // .content_about_us,
    // .content_impact {
    //     .banner_container {
    //         height: 220px !important;
    //     }
    // }

    .banner_video,
    .banner_thumbnail {
        height: 302px;
    }

    .responsive_banner_text_container {
        display: block !important;
        height: 306px;
        background-color: #1C1C1E;
        padding: 20px 16px 28px 16px;
        box-sizing: border-box;
        font-family: $font;
        color: #F5F5F7;

        .responsive_content_section_1 {
            @include text-styles(32px, $font, 600, $color_1, 36px, 0, 10px);
        }

        .banner_text_responsive {
            @include text-styles(20px, $font, 600, $color_1, 24px, 0, 18px);
        }
    }

    .alaia_button_container {
        &.about_us_section_2 {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}