@import './_common.scss';

.content_footer_wrapper {
    bottom: 120px;
    left: 0;
    width: 100%;
    background-color: $color_1;

    &.wellness {
        background-color: transparent;
    }

    &.cf_two {
        bottom: 0;
        background-color: $color_4;
    }
}

.content_footer_container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 34px 150px 48px 180px;
    box-sizing: border-box;

    &.cf_two {
        padding: 18px 160px;
        box-sizing: border-box;
    }
}


.content_footer {
    font-family: $font;
    width: 100%;
    align-items: center;
    letter-spacing: 0.2px;
    color: $color_4;
}

.content_footer__logo {
    width: 102px;
    height: 28px;
    cursor: pointer;
    margin-bottom: 54px;
}

.content_footer__columns {
    display: flex;
    flex-wrap: wrap;
}

.content_footer__column {
    flex: 1;

    &.content_images {
        margin-top: 60px;
        list-style-type: none;
        text-align: end;

        li img {
            width: 136px;
            height: 36px;
        }

    }

    &.fifth_column {
        margin-right: 64px;
    }
}

.content_footer__column ul {
    list-style-type: none;
    padding: 0;
}

.content_footer__column ul li {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 5px;
    cursor: pointer;
}

.content_footer__column ul li a {
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    line-height: 14px;
    margin-bottom: 5px;
    cursor: pointer;
    color: $color_5;
}

.content_footer_bold_menu {
    color: $color_2;
    cursor: default !important;
    font-weight: 700 !important;
}

.content_footer_first_column {
    margin-bottom: 16px !important;
}

.arrow_container {
    margin-top: 48px;

    .arrow {
        float: right;
        width: 24px;
        height: 12px;
        cursor: pointer;

        &::before {
            display: inline-flex;
            align-items: center;
            content: "\e969";
            font-family: "icomoon";
            color: #44423F;
            font-size: 16px;
        }
    }
}

.subfooter_container {
    padding: 18px 32px;
    box-sizing: border-box;
    cursor: pointer;
}

.content_footer_two {
    display: flex;
    max-width: 1024px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    color: $color_1;
}

.content_footer_two__left {
    display: flex;
    padding: 6px;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.content_footer_two__column {
    margin-right: 40px;

    ul {
        list-style-type: none;
        margin: 0px;
        padding: 0px;

        li {
            font-size: 10px;
            line-height: 16px;
            font-weight: 500;
            font-family: $font;
            color: $color_1;
            margin-bottom: 12px;
            cursor: pointer;

            a {
                text-decoration: none;
                color: #fff;
            }
        }
    }
}

.content_footer_two__right {
    display: flex;
    flex-wrap: wrap;
}

.content_footer_two__icons {
    width: 72px;
    height: 48px;
    margin-right: 64px;
    display: flex;
    align-items: end;
    justify-content: center;
    cursor: pointer;

    .icn {
        width: 36px;
        height: 36px;
        background-repeat: no-repeat;

        &.facebook {
            background-image: url('../../images/footer_facebook_icn.svg');
        }

        &.instagram {
            background-image: url('../../images/footer_instagram_icn.svg');
        }
    }
}

.dropdown_input_wrapper_footer {
    display: inline-block;
    width: 196px;
    height: 48px;
    background-color: transparent;
    border-radius: 32px;
    border: 2px solid $color_1;
    cursor: pointer;
    padding: 14px 22px;
    box-sizing: border-box;

    &.login {
        width: 164px;
        height: 34px;
        padding: 6px 22px;
    }

    .cte_input_title_dropdown {
        background-color: transparent;
        border: none;
        width: 90px;
        display: inline-block;
        font-family: $font;
        color: $color_1;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        height: 100%;
        letter-spacing: .2px;
        outline: none;
        cursor: pointer;
        vertical-align: top;

        &.login {
            width: 48px;
            line-height: 20px;
            font-size: 14px;
            margin-right: 8px;
        }
    }

    .drop_down_icn_left {
        display: inline-block;
        width: 32px;

        &::before {
            display: inline-flex;
            vertical-align: bottom;
            content: "\e968";
            font-family: "icomoon";
            height: 100%;
            color: $color_1;
            font-size: 20px;
        }

        &.login {
            width: 20px;
            margin-right: 16px;
        }
    }

    .drop_down_icn {
        display: inline-block;

        &::before {
            display: inline-flex;
            vertical-align: bottom;
            content: "\e967";
            font-family: "icomoon";
            height: 100%;
            color: $color_1;
            font-size: 20px;
        }
    }

    .drop_down_option_container {
        transform: translateY(-100%);
        display: inline-block;
        width: 196px;
        height: 40px;
        position: absolute;
        background-color: transparent;
        z-index: 5;
        border-radius: 4px;
        border: 1px solid $color_1;
        padding: 4px 0px 4px 0px;
        box-sizing: border-box;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
        overflow: scroll;
        margin-left: -186px;

        &.login {
            width: 164px;
        }

        &::-webkit-scrollbar {
            width: 1px;
        }

        // track
        &::-webkit-scrollbar-track {
            background-color: transparent;
            border-radius: 1px;
        }

        // handle
        &::-webkit-scrollbar-thumb {
            background-color: transparent;
            border-radius: 1px;
            background-clip: content-box;
            border-top: 1px transparent solid;
            border-bottom: 1px transparent solid;
            border-right: 1px transparent solid;
            height: 38px;
            width: 4px;

            // handle on hover
        }

        .option_container {
            display: inline-block;
            width: 100%;
            padding: 4px 4px 4px 14px;
            box-sizing: border-box;

            &:hover {
                background: rgba(80, 133, 242, 0.1);
            }

            .dropdown_option {
                width: 100%;
                display: inline-block;
                font-family: $font;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                height: 100%;
                letter-spacing: .2px;
                color: $color_1;
                cursor: pointer;
            }
        }
    }
}

.responsive_content_footer__logo_container {
    display: none;
}

.resposive_sub_footer {
    display: none;
}

@media screen and (max-width:1240px) {
    .content_footer_container {
        padding: 32px 20px 20px 20px;
    }

    .arrow_container {
        display: none;
    }

    .content_footer__logo {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
    }

    .responsive_content_footer__logo_container {
        display: flex;
        height: 38px;
        align-items: center;
        margin-bottom: 20px;
        cursor: pointer;

        img {
            width: 102px;
            height: 28px;
        }
    }

    .content_footer__logo {
        display: none;
    }


    .responsive_content_footer__column {
        flex: 1;

        &.content_images {
            list-style-type: none;
            text-align: end;
            margin-top: 0px;
        }
    }

    .logo_list {
        display: inline-block;
        height: 38px;


        &:last-child {
            margin-left: 12px;
        }
    }

    .content_footer__column {
        flex: 1;

        &.content_images {
            display: none;
        }

    }

    .content_footer__columns {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 12px;
    }

    .content_footer_container.cf_two {
        padding: 12px 20px 24px 20px
    }

    .subfooter_container {
        padding: 0px;
    }

    .content_footer_two {
        width: 100%;
        max-width: 100%;
    }

    .content_footer_two {
        flex-direction: column;
    }

    .content_footer_two__left {
        width: 100%;
    }

    .content_footer_two__right {
        width: 100%;
    }

    .sub_footer {
        display: none;
    }

    .resposive_sub_footer {
        display: block;
    }

    .content_footer_two__icons {
        justify-content: flex-start;
        flex-grow: 1;
        margin: 0px;
    }

}

@media screen and (max-width:720px) {
    .logo_list {
        display: block;
    }

    .responsive_content_footer__logo_container {
        height: 84px;
    }

    .content_footer_container {
        padding: 24px 16px 20px 16px;
    }

    .content_footer__column {
        margin-right: 24px;
    }
}