.page_wrapper {
  display: inline-block;
  width: 100%;
  overflow-x: hidden;
}

.page_container {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;

  &.main {
    padding-left: 166px;
    transition: all 0.4s ease-out;
  }
}

.main_wrapper {
  display: inline-block;
  width: 100%;
}

.main_container {
  display: inline-block;
  width: 100%;
  // padding: 24px 0px;
}

@media screen and (max-width: 1240px) {
  .page_container {
    &.main {
      padding-left: 0px;
      padding-top: 64px;
    }
  }
}