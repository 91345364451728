.content_page_wrapper {
    display: inline-block;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    // background-color: #fff;
}

.content_page_container {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;

    &.content {
        padding-top: 76px;
    }

    &.content_login {
        padding-top: 0px;
    }
}

.content_main_wrapper {
    display: inline-block;
    width: 100%;
}

.content_main_container {
    display: inline-block;
    width: 100%;
}

@media screen and (max-width: 1240px) {
    .content_page_container {
        &.main {
            padding-left: 0px;
            padding-top: 64px;
        }

        &.content {
            padding-left: 0px;
            padding-top: 86px !important;
        }
    }

}

@media screen and (max-width: 740px) {
    .content_page_container {
        &.content {
            padding-left: 0px;
            padding-top: 64px !important;
        }
    }

}