.alaia_loader_wrapper {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    left: 0;
    top: 0;
    font-size: 0;
    line-height: 1;

    .alaia_loader_container {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .alaia_loader {
        width: 280px;
        display: inline-block;
        color: #424242;
        border-radius: 20px;
        background-color: #ffffff;
        border: solid 1px #e8e8e8;
        text-align: left;
        padding: 24px 20px;
        text-align: center;

        &.custom {
            width: 212px;
            padding: 32px 30px;
            border-radius: 10px;
        }

        .loader_icon {
            display: block;
            margin: 0 auto;

            img {
                display: inline-block;
                width: 100px;
                height: 100px;
            }
        }

        .loader_text {
            display: inline-block;
            width: 100%;
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: #6a7384;
            margin-top: 16px;

            &.custom {
                font-weight: 400;
                line-height: 20px;
                color: #080707;
            }
        }
    }
}

.alaia_loader_timer {
    display: inline-block;
    width: 100%;
    font-size: 42px;
    line-height: 42px;
    font-weight: 600;
    color: #080707;
    letter-spacing: 0.2px;
}