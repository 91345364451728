$font: 'Roboto';

.feedback_container {
    position: fixed;
    bottom: 28px;
    right: 24px;
    background-color: #ffffff;
    box-shadow: 0px 1px 4px 0px #00000040;
    border-radius: 14px;
    color: #969492;

    &.default,
    &.clicked {
        display: flex;
        align-items: center;
        height: 28px;
        border-radius: 48px;
        z-index: 5;
        background-color: #ffffff;
        box-shadow: 0px 1px 4px 0px #00000040;
        padding: 1px 10px 1px 10px;
        color: #969492;
        cursor: pointer;

        .feedback_icon {
            content: url('../images/feedback.svg');
            width: 18px;
            height: 16px;
            margin-right: 4px;
        }
    }

    &.opened {
        width: 434px;

        .feedback_header_container {
            width: 100%;
            display: inline-flex;
            flex-grow: 1;
            align-items: center;
            height: 58px;
            border-bottom: 1px solid #DDDBDA;
            padding: 16px 24px;
            box-sizing: border-box;

            .feedback_icon {
                content: url('../images/feedback.svg');
                width: 24px;
                height: 22px;
                margin-right: 4px;
            }

            .feedback_header_text {
                font-family: $font;
                font-weight: 700;
                letter-spacing: 0.2px;
                font-size: 18px;
                line-height: 28px;
                color: #969492;
                width: 100%;

                &::before {
                    // font-family: 'icomoon';


                }
            }

            .feedback_header_close_btn {
                cursor: pointer;
                width: 24px;
                height: 24px;
                display: flex;
                border-radius: 50%;
                border: 1px solid var(--Strokes-Secondary, #DDDBDA);
                background-color: #F3F2F2;
                align-items: center;
                justify-content: center;

                &::before {
                    content: '\e923';
                    display: inline-block;
                    font-family: 'icomoon';
                    color: #706E6B;
                    font-size: 10px;
                }
            }
        }

        .feedback_input_container {
            padding: 24px 24px 12px;
            box-sizing: border-box;
            border-bottom: 1px solid #DDDBDA;

            .input_container {
                position: relative;

                .feedback_input_title {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    color: #3E3E3C;
                    padding-bottom: 2px;
                }

                .feedback_input {
                    width: 100%;
                    height: 250px;
                    outline: none;
                    border-radius: 4px;
                    border: 1px solid #DDDBDA;
                    resize: none;
                    font-family: $font;
                    font-weight: 400;
                    letter-spacing: 0.2px;
                    font-size: 14px;
                    line-height: 20px;
                    color: #080707;
                    box-sizing: border-box;
                    cursor: auto;
                    margin-bottom: 16px;

                    &:focus {
                        border: 1px solid #1589EE;
                    }

                    &.subject {
                        height: 32px;
                        padding: 6px 0px 6px 12px;
                    }

                    &.content {
                        height: 162px;
                        padding: 6px 12px;
                    }
                }

                .error_msg {
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 500;
                    letter-spacing: 1px;
                    color: #e52a34;
                    left: 0;
                    text-align: left;
                    bottom: 0;
                    position: absolute;
                    padding-bottom: 12px;
                }

                .feedback_text_length {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: left;
                    color: #706E6B;
                    position: absolute;
                    right: 8px;
                    bottom: 24px;
                }
            }

            .feedback_message {
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;

                a {
                    color: #006DCC;
                }
            }
        }

        .feedback_button_container {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 64px;

            .option_button {
                width: 100px;
                padding: 6px 10px;
                box-sizing: border-box;
                font-family: $font;
                font-weight: 400;
                line-height: 20px;
                font-size: 13px;
                cursor: pointer;
                border-radius: 4px;
                margin-right: 12px;

                &.cancel {
                    color: #0070D2;
                    border-style: none;
                    background-color: #fff;
                }

                &.send {
                    color: #fff;
                    border: 1px solid #0070D2;
                    background-color: #0070D2;
                    margin-right: 32px;
                }
            }
        }
    }
}

@media screen and (max-width:850px) {
    .feedback_container {
        bottom: 142px;
    }
}

@media screen and (max-width:720px) {
    .feedback_container {
        bottom: 90px;

        &.opened {
            bottom: 0px;
            right: 0px;
            width: fit-content;
        }
    }
}