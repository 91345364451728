.session_wrapper {
    width: 280px;
    height: 184px;
    // flex-basis: 25%;
    border-radius: 14px;
    display: inline-block;
    overflow: hidden;
    box-sizing: border-box;
    cursor: pointer;

    .session_container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: inline-block;
        border-radius: 14px;
        background-color: #d3d3d3;
        // -webkit-transition: .3s ease-in-out;
        // -moz-transition: .3s ease-in-out;
        // -o-transition: .3s ease-in-out;
        // transition: .3s ease-in-out;

        &.custom {
            display: flex;
            justify-content: space-between;
        }
    }
}

.session_wrapper:hover {
    border-radius: 14px;
}

// .session_wrapper:hover .session_container,
// .session_wrapper:focus .session_container {
//     -ms-transform: scale(1.3);
//     -moz-transform: scale(1.3);
//     -webkit-transform: scale(1.3);
//     -o-transform: scale(1.3);
//     transform: scale(1.3);
//     border-radius: 14px;
// }

.search_session_wrapper {
    width: 100%;
    height: 184px;
    // flex-basis: 25%;
    border-radius: 14px;
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;

    .search_session_container {
        width: 100%;
        height: 100%;
        display: inline-block;
        border-radius: 14px;
        background-color: #d3d3d3;
    }
}

@mixin input-error {
    &::before {
        // content: url('../images/icn_input_error.svg');
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        flex-shrink: 0;
    }

    span {
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        color: #e52a34;
        text-align: left;
    }
}

.error_msg_container {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-bottom: 24px;

    .error_msg {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;

        @include input-error;
    }
}

// // responsive

// // left side menu responsive
// @media screen and (max-width: 1360px) {
//     .page_container {
//         &.main {
//             padding-left: 216px;
//         }
//     }
// }

// // left side menu responsive
// @media screen and (max-width: 1240px) {
//     .page_container {
//         &.main {
//             padding-left: 0;
//         }
//     }
// }