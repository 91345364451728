$font: 'Roboto';

@font-face {
    src: url('../../fonts/icomoon.woff') format('woff'),
        url('../../fonts/icomoon.ttf') format('truetype');
    font-style: normal;

    font: {
        family: 'icomoon';
        weight: normal;
    }
}

.feature_tour_wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #303030CC;
    z-index: 5;

    .feature_tour_container {
        width: 100%;
        height: 100%;
    }
}

.feature_tour_image_container {
    &.custom_exercise {
        width: 312px;
        border-radius: 8px;
        height: 165px;
        margin-bottom: 12px;
    }
}

.feature_tour_header {
    font-family: $font;

    &.custom_exercise {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        color: #080707;
        margin-bottom: 4px;
    }
}

.feature_tour_description {
    font-family: $font;

    &.custom_exercise {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: #000000;
        margin-bottom: 16px;
    }
}

.feature_tour_btn_container {
    width: 100%;
    padding: 6px 0px;

    .feature_tour_btn {
        width: 100%;
        outline: none;
        border: none;
        font-family: $font;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        margin-bottom: 8px;
        padding: 6px 0px;
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;

        &:last-child {
            margin-bottom: 0px;
        }

        &.submit {
            background-color: #0070D2;
            color: #fff;
        }

        &.cancel {
            border: 1px solid #0070D2;
            color: #0070D2;
            background-color: #fff;
        }
    }
}

.custom_exercise_feature_tour_wrapper {
    max-width: 344px;
    max-height: 382px;
    z-index: 6;

    .custom_exercise_feature_tour_tooltip_container {
        width: 100%;
        height: 18px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .custom_exercise_feature_tour_tooltip {
            width: 30px;
            height: 18px;
            margin-top: 4px;
            margin-right: 12px;

            &::before {
                content: '\e97c';
                display: inline-block;
                font-family: 'icomoon';
                color: #fff;
                font-size: 18px;
                cursor: pointer;
            }
        }
    }


    .custom_exercise_feature_tour_container {
        border-radius: 6px;
        padding: 16px;
        background-color: #fff;
    }
}