// @import "@fontsource/roboto";
$font: 'Roboto';

@font-face {
    src: url('../../fonts/icomoon.woff') format('woff'),
        url('../../fonts/icomoon.ttf') format('truetype');
    font-style: normal;

    font: {
        family: 'icomoon';
        weight: normal;
    }
}

.nav_header_wrapper {
    display: none;
}

.nav_bar_logo_wrapper {
    display: inline-block;
    width: 166px;
    position: fixed;
    left: 0;
    top: 0;
    // background-color: #ffffff;
    // border: 1px solid #D5D5D5;
    // border-bottom: none;

    .nav_bar_logo_container {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        padding: 26.5px 24px 26.5px 24px;
        box-sizing: border-box;
        z-index: 5;
    }
}

.nav_bar_wrapper {
    display: inline-block;
    margin-top: 81px;
    width: 56px;
    overflow-x: hidden;
    height: -webkit-fill-available;
    height: calc(100% - 113px);
    border-radius: 12px;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #ffffff;
    // border: 1px solid #D5D5D5;
    border-top: none;
    // transition: all 0.4s ease-out;
    transition-timing-function: ease-in;

    /* Quick on the way out */
    transition: 0.2s;
    overflow-y: hidden;

    /* Hide thing by pushing it outside by default */
    // transform: translateX(0%);
    cursor: pointer;
    z-index: 3;
    margin-left: 24px;

    &:hover {
        width: 140px;

        transition-timing-function: ease-out;

        /* A litttttle slower on the way in */
        transition: 0.25s;

        /* Move into place */
        // transform: translateX(12%);
    }

    .nav_bar_container {
        display: flex;
        // width: 100%;
        height: 100%;
        flex-direction: column;
        padding: 16px 16px 16px 16px;
        box-sizing: border-box;
    }
}

.nav_bar_logout_container {
    display: inline-flex;
    flex-grow: 1;
    align-items: flex-end;
    margin-bottom: 40px;

    .nav_bar_logout_button {
        display: inline-flex;

        &.active {
            &::before {
                color: #008AFF;
            }
        }

        &::before {
            content: '\e94a';
            display: inline-block;
            font-family: 'icomoon';
            color: #BEBEBE;
            font-size: 24px;
            cursor: pointer;
        }

        &:hover {

            &::before {
                color: #008AFF;
            }
        }
    }
}

.logout_popup {
    display: inline-block;
    width: 152px;
    position: fixed;
    border-radius: 8px;
    background-color: #fff;
    padding: 8px 8px;
    box-sizing: border-box;
    box-shadow: 4px 4px 20px 12px rgba(0, 0, 0, 0.1);
    z-index: 5;
    margin-left: -85px;
    margin-bottom: 20px;
    bottom: 0;
    cursor: pointer;
}

.logout_icn {
    display: inline-block;
    margin-right: 10px;

    &.logout {
        &::before {
            content: '\e95d';
            display: inline-block;
            font-family: 'icomoon';
            color: #BEBEBE;
            font-size: 24px;
            font-weight: 500;
        }
    }

    &.settings {
        &::before {
            content: '\e95c';
            display: inline-block;
            font-family: 'icomoon';
            color: #BEBEBE;
            font-size: 24px;
            font-weight: 500;
        }
    }
}

.logout_popup_button {
    display: inline-flex;
    width: 100%;
    // justify-content: center;
    padding: 8px 16px;
    box-sizing: border-box;
    align-items: center;
    height: 46px;
    font-family: $font;
    letter-spacing: 0.2px;
    font-weight: 700;
    color: #424242;
    line-height: 16px;
    font-size: 14px;

    &.border {
        border-bottom: 0.33px solid rgba(60, 60, 67, 0.36);
    }

    &:hover {
        .logout_icn {
            &::before {
                color: #008AFF;
            }
        }
    }
}

.nav_ul {
    display: inline-block;
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.nav_li {
    display: inline-block;
    width: 100%;
    height: 48px;
    margin-bottom: 12px;

    &:last-child {
        margin-bottom: 0;
    }
}

.nav_anchor {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    cursor: pointer;
    text-decoration: none;

    &::before {
        display: inline-block;
        flex-shrink: 0;
        font-family: 'app_font_icon';
        font-size: 24px;
        color: #4e4e4e;
        flex-shrink: 0;
    }

    &.active {
        // border-right: 3px solid #008AFF;

        .nav_title {
            color: #008AFF;
        }
    }

    &:hover {
        // background-color: rgba(49, 136, 244, 0.3); // #3188f4
        // border-radius: 24px;

        &::before {
            color: #3188f4;
        }

        .nav_title {
            color: #3188f4;
        }
    }

    &.home {
        &::before {
            // content: url('../../images/home.svg');
            content: '\e900';
            display: inline-block;
            font-family: 'icomoon';
            color: #BEBEBE;
            font-size: 24px;
        }

        &:hover,
        &.active {
            &::before {
                color: #3188f4;
            }
        }
    }

    &.activity {
        &::before {
            content: '\e932';
            display: inline-block;
            font-family: 'icomoon';
            color: #BEBEBE;
            font-size: 24px;
        }

        &:hover,
        &.active {
            &::before {
                color: #3188f4;
            }
        }
    }

    &.discover {
        &::before {
            content: '\e91a';
            display: inline-block;
            font-family: 'icomoon';
            color: #BEBEBE;
            font-size: 24px;
        }

        &:hover,
        &.active {
            &::before {
                color: #3188f4;
            }
        }
    }

    &.reflect {
        &::before {
            content: '\e906';
            display: inline-block;
            font-family: 'icomoon';
            color: #BEBEBE;
            font-size: 24px;
        }

        &:hover,
        &.active {
            &::before {
                color: #3188f4;
            }
        }
    }

    &.learn {


        &::before {
            content: '\e959';
            display: inline-block;
            font-family: 'icomoon';
            color: #BEBEBE;
            font-size: 20px;
            margin-left: 4px;
        }

        &:hover,
        &.active {
            &::before {
                color: #3188f4;
            }
        }
    }

}

.nav_title {
    display: inline-block;
    font-family: $font;
    letter-spacing: 0.2px;
    font-weight: 700;
    color: #3f4260;
    margin-left: 16px;
    flex-grow: 1;
    font-size: 14px;
    white-space: nowrap;
    // transition: all 0.4s ease-out;
}

.logo_container {
    content: url('../../images/Alaia.svg');
    height: 28px;
    width: 102px;
    // padding-top: 24px;
    // padding-bottom: 40px;
}

.nav_header_wrapper.show_responsive.nav_bar_wrapper {
    left: 0;
}

// left side menu responsive
@media screen and (max-width: 1360px) {
    .nav_bar_wrapper {

        .nav_ul {
            padding-right: 0px;
        }

        // .nav_title {
        //     margin-left: 8px;
        // }
    }
}

// left side menu responsive
@media screen and (max-width: 1240px) {

    .nav_bar_wrapper:hover {
        width: 208px;
    }

    .logout_popup {
        margin-left: 50px;
        margin-bottom: 16px
    }

    .nav_bar_logo_wrapper {
        display: none;
    }

    .nav_bar_wrapper {
        width: 208px;
        left: -228px;
        margin-top: 0px;
        z-index: 2;
        height: 100%;
        border-radius: 0px;
        margin-left: 0px;
        transition: left 0.4s ease-out;

        .nav_bar_container {
            // .logo_container {
            //     display: none !important;
            // }

            .icon_menu {
                &::before {
                    content: '\e91c';
                    display: inline-block;
                    font-family: 'icomoon';
                    color: #424242;
                    font-size: 24px;
                }

                margin-bottom: 19px;
                display: inline-flex;
                align-items: center;
                cursor: pointer;
                margin-top: 20px;
            }
        }

        .nav_header_wrapper.show_responsive+& {
            left: 0;
            z-index: 5;
        }
    }

    .nav_header_wrapper {
        display: inline-block;
        width: 100%;
        height: 64px;
        // box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
        background-color: #fff;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin: 0 auto;
        text-align: left;
        z-index: 2;

        &.show_responsive .nav_menu_mask {
            display: inline-block;
            position: fixed;
            width: 100vw;
            height: 100vh;
            z-index: 51;
            background-color: #000;
            opacity: .5;
            left: 0;
            top: 0;
        }

        .nav_header_container {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: flex-start;
            margin: 0 auto;
            max-width: 1440px;

            .icon_menu {
                &::before {
                    content: '\e91c';
                    display: inline-block;
                    font-family: 'icomoon';
                    color: #424242;
                    font-size: 24px;
                }

                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-left: 24px;
                box-sizing: border-box;
                cursor: pointer;
            }

            .nav_logo_container {
                height: 28px;
                padding-top: 16px;
                width: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                vertical-align: middle;

                &::before {
                    content: url('../../images/Alaia.svg');
                    margin-left: -64px;
                }
            }
        }
    }
}