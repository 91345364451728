// @import "@fontsource/roboto";
$font: 'Roboto';

@font-face {
    src: url('../../fonts/icomoon.woff') format('woff'),
        url('../../fonts/icomoon.ttf') format('truetype');
    font-style: normal;

    font: {
        family: 'icomoon';
        weight: normal;
    }
}

// .nav_bar_wrapper {
//     display: none;
// }

// .nav_header_wrapper {
//     display: none;
// }

// .page_container {

//     &.main {
//         padding-left: 0px;
//     }
// }

.audio_player_wrapper {
    align-items: center;
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    top: 0px;
    position: absolute;
    min-height: 100vh;
    left: 0px;
    z-index: 4;

    &.minimize {
        height: 80px;
        position: fixed;
        width: 100%;
        left: 0px;
        top: auto;
        min-height: auto;
        z-index: 4;
        bottom: 0px;
    }

    .audio_player_whole_container {
        align-items: center;
        display: inline-flex;
        flex-direction: column;
        width: 100%;
        padding: 20px 24px;
        padding-bottom: 0px;
        box-sizing: border-box;
        min-height: 100vh;
        z-index: 3;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        backdrop-filter: blur(8px);
        background-color: rgba(255, 255, 255, 0.15);
        -webkit-backdrop-filter: blur(8px);

        &.no_background {
            background-color: #ab7da9;

            &::after {
                background: none;
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            filter: blur(10px);
            z-index: -1;
        }

        .pause_play_container_mini {
            display: inline-flex;
            vertical-align: middle;
            justify-content: center;
            align-items: center;
            padding: 0px 10px 10px 10px;

            .minimized_pause_button {
                width: 38px;
                height: 38px;
                cursor: pointer;
                margin-bottom: -8px;
                margin-right: 10px;
                content: url('../../images/minimized_pause.svg')
            }

            .minimized_play_button {
                display: inline-block;
                margin-top: 12px;
                margin-right: 8px;

                &::before {
                    display: inline-block;
                    cursor: pointer;
                    content: url('../../images/minimized_play.svg');
                    height: 44px;
                    width: 40px;
                    // margin-left: 32px;
                }

                // &:hover {
                //     display: inline-block;
                //     cursor: pointer;
                //     content: url('../../images/mindfulness/play_hover_icn.svg');
                //     height: 72px;
                //     width: 72px;
                //     margin-left: -10px;
                //     margin-top: -2px;
                // }
            }
        }

        &.explore {
            width: 72%;
        }

        &.minimize {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;
            min-height: auto;
            justify-content: center;
            padding: 0px;
        }

        .audio_player_top_container {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;

            .back_btn_container {
                width: 85%;
                padding-bottom: 120px;
                box-sizing: border-box;
                display: inline-block;

                .back_icn {
                    height: 36px;

                    &::before {
                        content: '\e917';
                        display: inline-block;
                        font-family: 'icomoon';
                        color: #F8F8F4;
                        font-size: 24px;
                        margin-right: 16px;
                        margin-top: 3px;
                        cursor: pointer;
                    }

                    &:hover {
                        &::before {
                            content: '\e917';
                            display: inline-block;
                            font-family: 'icomoon';
                            color: #FFFFFF;
                            font-size: 24px;
                            margin-right: 16px;
                            margin-top: 3px;
                            cursor: pointer;
                        }
                    }
                }
            }

            .explore_more_title_container {
                display: inline-flex;
                flex-grow: 1;
                max-width: 204px;
                background-color: #FFFFFF;
                align-items: center;
                height: 48px;
                padding: 6px 0px 6px 16px;
                cursor: pointer;
                border-radius: 4px;
                border: 1.11px solid var(--Strokes-Button-Brand, #0070D2);
                box-sizing: border-box;

                .explore_more_icon {
                    content: url('../../images/explore_more.svg');
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                }

                .explore_more_text {
                    font-family: $font;
                    font-weight: 600;
                    letter-spacing: 0.2px;
                    font-size: 17px;
                    line-height: 26px;
                    color: #0070D2;
                    width: 100%;

                    &::before {
                        // font-family: 'icomoon';


                    }
                }
            }
        }

        .audio_player_container {
            width: 100%;
            display: inline-block;
            text-align: center;

            .duration_value {
                width: 64px;
                display: inline-flex;
                font-family: $font;
                letter-spacing: 0.2px;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;
                vertical-align: middle;
                justify-content: center;
            }

            .progressBar {
                --bar-bg: rgba(255, 255, 255, 0.4);
                --seek-before-width: 0;
                --seek-before-color: #FFFFFF;
                // --knobby: #3452a5;
                // --selectedKnobby: #26c9c3;

                appearance: none;
                background: var(--bar-bg);
                border-radius: 10px;
                position: relative;
                width: 600px;
                height: 6px;
                outline: none;
            }

            /* progress bar - safari */
            .progressBar::-webkit-slider-runnable-track {
                background: var(--bar-bg);
                border-radius: 10px;
                position: relative;
                width: 100%;
                height: 6px;
                outline: none;
            }

            /* progress bar - firefox */
            .progressBar::-moz-range-track {
                background: var(--bar-bg);
                border-radius: 10px;
                position: relative;
                width: 100%;
                height: 6px;
                outline: none;
            }

            .progressBar::-moz-focus-outer {
                border: 0;
            }

            /* progress bar - chrome and safari */
            .progressBar::before {
                content: '';
                height: 6px;
                width: var(--seek-before-width);
                background-color: var(--seek-before-color);
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                // cursor: pointer;
            }

            /* progress bar - firefox */
            .progressBar::-moz-range-progress {
                background-color: var(--seek-before-color);
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                height: 6px;
            }

            /* knobby - chrome and safari */
            .progressBar::-webkit-slider-thumb {
                -webkit-appearance: none;
                height: 15px;
                width: 15px;
                border-radius: 50%;
                border: none;
                background-color: var(--knobby);
                // cursor: pointer;
                position: relative;
                margin: -2px 0 0 0;
                z-index: 3;
                box-sizing: border-box;
            }

            /* knobby while dragging - chrome and safari */
            .progressBar:active::-webkit-slider-thumb {
                transform: scale(1.2);
                background: var(--selectedKnobby);
            }

            /* knobby - firefox */
            .progressBar::-moz-range-thumb {
                height: 15px;
                width: 15px;
                border-radius: 50%;
                border: transparent;
                background-color: var(--knobby);
                // cursor: pointer;
                position: relative;
                z-index: 3;
                box-sizing: border-box;
            }

            /* knobby while dragging - firefox */
            .progressBar:active::-moz-range-thumb {
                transform: scale(1.2);
                background: var(--selectedKnobby);
            }
        }
    }

    .explore_more_container {
        width: 28%;
        position: fixed;
        right: -28%;
        min-height: 100vh;
        top: 0px;
        z-index: 2;
        background-color: #F8F8F8;
        transition: right 1s ease;

        &.opened {
            right: 0px;
            overflow: scroll;
            max-height: 100%;
        }

        .explore_more_top_container {
            display: flex;
            height: 80px;
            padding: 24px;
            box-sizing: border-box;
            border-bottom: 1px solid #D5D5D5;
            align-items: center;
            justify-content: space-between;

            .explore_more_text_container {
                display: flex;

                .explore_more_back_button {
                    content: url('../../images/back_icn.svg');
                    width: 24px;
                    height: 24px;
                    margin-right: 16px;
                    cursor: pointer;
                }


                .explore_more_top_title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #424242;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 22px;
                    text-align: left;

                    // &::before {
                    //     content: '\e917';
                    //     display: inline-block;
                    //     font-family: "icomoon";
                    //     color: #5E5E5E;
                    //     font-size: 24px;
                    //     margin-right: 16px;
                    //     margin-top: 3px;
                    //     cursor: pointer;
                    // }

                }
            }

            .explore_more_view_all {
                color: #0070D2;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                padding: 5px 16px;
                border-radius: 68px;
                cursor: pointer;
                border: 1px solid var(--Strokes-Button-Brand, #0070D2)
            }
        }

        a {
            text-decoration: none;
        }
    }
}

.mindfulness_session_group_container {
    width: 100%;
    display: inline-block;
    padding: 8px 16px;
    box-sizing: border-box;

    .audio_session_list_container {
        width: 100%;
        display: flex;
        flex-flow: row;
        padding: 0px 8px 8px 8px;
        box-sizing: border-box;
    }
}

.scroll_button {
    width: 40px;
    height: 40px;
    position: fixed;
    border-radius: 50%;
    text-align: center;
    right: 13%;
    background-color: rgba(255, 255, 255, 0.9490196078);
    bottom: 100px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    .scroll_icon {
        width: 30px;
        height: 30px;
        content: url('../../images/mindfulness/down_arrow.svg');

        &.up {
            transform: rotate(180deg);
        }

        &:hover {
            content: url('../../images/mindfulness/down_arrow_blue.svg');
            width: 30px;
            height: 30px;
        }
    }

    &.closed {
        display: none;
    }
}

.content_ul_list {

    width: 100%;
    // overflow-x: scroll;
    // overflow-y: hidden;
    padding: 0px;
    display: inline-flex;
    flex-direction: column;
    margin: 0px;

    .content_list {
        width: 100%;
        display: inline-block;
        padding: 8px 16px 8px 4px;

        &:nth-child(4) {
            padding-right: 0px;
        }
    }
}

.session_wrapper {
    &.explore_more {
        height: 128px;
    }
}

.minimized_audio_player_wrapper {
    height: 80px;
    position: fixed;
    width: 100%;
    background-color: #AB7DA9;
    left: 0px;
    z-index: 4;
    bottom: 0px;

    .minimized_audio_player_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        justify-content: center;

        .pause_play_container {
            display: inline-flex;
            vertical-align: middle;
            justify-content: center;
            align-items: center;
            padding: 0px 10px 10px 10px;

            .minimized_pause_button {
                width: 36px;
                height: 36px;
                cursor: pointer;
                margin-bottom: -8px;
                margin-right: 10px;
                content: url('../../images/minimized_pause.svg')
            }

            .minimized_play_button {
                display: inline-block;
                // padding-top: 12px;

                &::before {
                    display: inline-block;
                    cursor: pointer;
                    content: url('../../images/mindfulness/play_icn.svg');
                    height: 44px;
                    width: 40px;
                    // margin-left: 32px;
                }

                &:hover {
                    display: inline-block;
                    cursor: pointer;
                    content: url('../../images/mindfulness/play_hover_icn.svg');
                    height: 72px;
                    width: 72px;
                    margin-left: -10px;
                    margin-top: -2px;
                }
            }
        }

        .mini_audio_player_container {
            display: flex;
            flex-direction: column;

            .audio_title {
                color: #FFFFFF;
                height: 24px;
                font-size: 13px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                display: flex;
                margin-bottom: 4px;
                align-items: center;

                &::before {
                    display: inline-block;
                    cursor: pointer;
                    content: url('../../images/admin/Learn/audio_preview_icn.svg');
                    height: 18px;
                    width: 28px;
                }
            }

            .progressBar {
                --bar-bg: rgba(255, 255, 255, 0.4);
                --seek-before-width: 0;
                --seek-before-color: #FFFFFF;
                // --knobby: #3452a5;
                // --selectedKnobby: #26c9c3;

                appearance: none;
                background: var(--bar-bg);
                border-radius: 10px;
                position: relative;
                width: 600px;
                height: 6px;
                outline: none;
            }

            /* progress bar - safari */
            .progressBar::-webkit-slider-runnable-track {
                background: var(--bar-bg);
                border-radius: 10px;
                position: relative;
                width: 100%;
                height: 6px;
                outline: none;
            }

            /* progress bar - firefox */
            .progressBar::-moz-range-track {
                background: var(--bar-bg);
                border-radius: 10px;
                position: relative;
                width: 100%;
                height: 6px;
                outline: none;
            }

            .progressBar::-moz-focus-outer {
                border: 0;
            }

            /* progress bar - chrome and safari */
            .progressBar::before {
                content: '';
                height: 6px;
                width: var(--seek-before-width);
                background-color: var(--seek-before-color);
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                // cursor: pointer;
            }

            /* progress bar - firefox */
            .progressBar::-moz-range-progress {
                background-color: var(--seek-before-color);
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                height: 6px;
            }

            /* knobby - chrome and safari */
            .progressBar::-webkit-slider-thumb {
                -webkit-appearance: none;
                height: 15px;
                width: 15px;
                border-radius: 50%;
                border: none;
                background-color: var(--knobby);
                // cursor: pointer;
                position: relative;
                margin: -2px 0 0 0;
                z-index: 3;
                box-sizing: border-box;
            }

            /* knobby while dragging - chrome and safari */
            .progressBar:active::-webkit-slider-thumb {
                transform: scale(1.2);
                background: var(--selectedKnobby);
            }

            /* knobby - firefox */
            .progressBar::-moz-range-thumb {
                height: 15px;
                width: 15px;
                border-radius: 50%;
                border: transparent;
                background-color: var(--knobby);
                // cursor: pointer;
                position: relative;
                z-index: 3;
                box-sizing: border-box;
            }

            /* knobby while dragging - firefox */
            .progressBar:active::-moz-range-thumb {
                transform: scale(1.2);
                background: var(--selectedKnobby);
            }

            .duration_container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 6px;

                .duration_values {
                    width: 64px;
                    display: inline-flex;
                    font-family: $font;
                    letter-spacing: 0.2px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #FFFFFF;
                    vertical-align: middle;

                    &.left {
                        justify-content: flex-start;
                    }

                    &.right {
                        justify-content: flex-end;
                    }
                }
            }
        }

        .maximize_button {
            width: 36px;
            height: 36px;
            cursor: pointer;
            margin-left: 12px;
            content: url('../../images/maximize.svg')
        }
    }
}

.button_wrapper {
    display: inline-block;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-bottom: 32px;
    box-sizing: border-box;

    .button_container {
        display: inline-flex;
        width: 100%;
        height: 48px;
        align-items: center;
        justify-content: center;

        .pause_play_container {
            display: inline-flex;
            vertical-align: middle;
            padding: 10px;
            width: 240px;
            justify-content: center;
            align-items: center;

            .pause_button {
                display: inline-block;

                &::before {
                    content: '\e910';
                    display: inline-block;
                    font-family: 'icomoon';
                    color: #FFFFFF;
                    font-size: 28px;
                    cursor: pointer;
                }
            }

            .play_button {
                display: inline-block;
                // padding-top: 12px;

                &::before {
                    display: inline-block;
                    cursor: pointer;
                    content: url('../../images/mindfulness/play_icn.svg');
                    height: 44px;
                    width: 40px;
                    // margin-left: 32px;
                }

                &:hover {
                    display: inline-block;
                    cursor: pointer;
                    content: url('../../images/mindfulness/play_hover_icn.svg');
                    height: 72px;
                    width: 72px;
                    margin-left: -10px;
                    margin-top: -2px;
                }
            }
        }

        .fav_icn {
            &::before {
                content: '\e90d';
                display: inline-block;
                font-family: 'icomoon';
                color: #FFFFFF;
                font-size: 24px;
                cursor: pointer;
            }

            &.active {
                &::before {
                    content: '\e915';
                    display: inline-block;
                    font-family: 'icomoon';
                    color: #FFFFFF;
                    font-size: 24px;
                    cursor: pointer;
                }
            }

            // content: url('../../images/mindfulness/fav_icn.svg')
        }

        .backward_audio {
            &::before {
                display: inline-block;
                content: '\e90b';
                font-family: 'icomoon';
                font-size: 24px;
                color: #FFFFFF;
                cursor: pointer;
            }

            &:hover {
                &::before {
                    display: inline-block;
                    content: '\e920';
                    font-family: 'icomoon';
                    font-size: 24px;
                    color: #FFFFFF;
                    cursor: pointer;
                }
            }

            // content: url('../../images/mindfulness/backward.svg')
        }

    }
}

.duration_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 6px;

    .duration_values {
        width: 64px;
        display: inline-flex;
        font-family: $font;
        letter-spacing: 0.2px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #FFFFFF;
        vertical-align: middle;

        &.left {
            justify-content: flex-start;
        }

        &.right {
            justify-content: flex-end;
        }
    }
}

.audio_new_container {
    background: #FFFFFF1A;
    padding: 32px;
    border-radius: 48px;

    &.minimize {
        background: none;
        padding: 0px;
    }

    .audio_title_container {
        display: inline-block;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .audio_title {
            width: 100%;
            font-family: $font;
            letter-spacing: 0.2px;
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            text-align: center;
            color: #FFFFFF;
            padding-bottom: 80px;

            &.minimize {
                width: 100%;
                color: #FFFFFF;
                height: 24px;
                font-size: 13px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                display: flex;
                margin-bottom: -4px;
                align-items: center;
                padding-bottom: 0px;

                &::before {
                    display: inline-block;
                    cursor: pointer;
                    content: url('../../images/admin/Learn/audio_preview_icn.svg');
                    height: 18px;
                    width: 28px;
                }
            }
        }

        &.minimize {
            display: flex;
            flex-direction: column;
            width: auto;
        }
    }
}

.ambience_audio_container {
    display: inline-flex;
    height: 100%;
    width: 100%;

    .ambience_ref_container {
        display: inline-block;
        width: 100%;
    }
}

.volume_control_wrapper {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    height: 104px;
    justify-content: center;
    align-items: flex-end;
    gap: 12px;

    .volume_control_container {
        display: flex;
        width: 100%;
        height: 100%;
        text-align: center;
        justify-content: space-evenly;
        max-width: 600px;
        align-items: flex-end;

        .volume_control {
            display: inline-block;
            width: 24px;
            height: 24px;
            content: url('../../images/mindfulness/volume.svg');
            cursor: pointer;

            &:hover {
                width: 40px;
                height: 40px;
                margin-bottom: -8px;
                content: url('../../images/mindfulness/volume_hover.svg')
            }
        }

        .playback_control {
            display: inline-block;
            width: 28px;
            height: 28px;
            content: url('../../images/mindfulness/playback.svg');
            cursor: pointer;

            &:hover {
                width: 40px;
                height: 40px;
                margin-bottom: -8px;
                content: url('../../images/mindfulness/playback_hover.svg')
            }
        }
    }

    .volume_control_empty_cont {
        width: 40px;
        height: 40px;
    }

    .minimize_button {
        width: 36px;
        height: 36px;
        cursor: pointer;
        margin-bottom: -8px;
        content: url('../../images/mindfulness/minimize.svg')
    }

    .maximize_button {
        width: 36px;
        height: 36px;
        cursor: pointer;
        margin-bottom: -8px;
        content: url('../../images/mindfulness/minimize.svg')
    }
}

.maximize_button {
    width: 36px;
    height: 36px;
    cursor: pointer;
    margin-left: 12px;
    content: url('../../images/maximize.svg')
}

.playback_control_container {
    padding: 24px 24px 0px 24px;
    box-sizing: border-box;
    position: absolute;
    top: 50px;
    background-color: #fff;
    box-shadow: 0px 20px 24px -4px #10182814;
    border-radius: 12px;
}

.playback_container {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 56px 0px;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;

    .speedDisplay_container {

        position: absolute;
        top: 14px;
        /* Adjust the position as needed */
        left: 50%;
        transform: translateX(-50%);
        width: 240px;

        .speedDisplay {
            position: absolute;
            width: 28px;
            background: transparent;
            padding: 5px;
            border-radius: 3px;
            font-size: 18px;
            line-height: 28px;
            font-weight: 600;
            margin-left: 92px;
            margin-bottom: 32px;
            color: #080707;
        }
    }

}

.playback_bar_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .minus_container {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #8E8E93;
        content: url('../../images/Minus.svg');
    }

    .plus_container {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #8E8E93;
        content: url('../../images/Plus.svg');
    }
}

.speed_value_container {
    margin-top: 12px;
    position: relative;
    background: #CDCDCD;
    width: 51px;
    display: flex;
    height: 25px;
    padding: 4px 12px 4px 12px;
    gap: 10px;
    left: 46%;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.active {
        background-color: #0070D2;
        color: #FFFFFF;
    }
}

.playbackBar {
    width: 300px;
    display: inline-block;
    cursor: pointer;
}


.playbackBar {
    -webkit-appearance: none;
    margin-right: 16px;
    margin-left: 16px;
    margin-top: 28px;
    margin-bottom: 28px;
    width: 424px;
    height: 4px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    /* Style for the runnable track */
    &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        height: 7px;
        border-radius: 5px;
        background: transparent;
        /* Keep it transparent */
    }

    /* Thumb styling */
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #000;
        cursor: ew-resize;
        box-shadow: 0 0 2px 0 #555;
        transition: background .3s ease-in-out;
        cursor: pointer;
        margin-top: -7px;
        /* Fix thumb alignment */
    }
}

.playback_speed_options_container {
    display: flex;
    flex-direction: row;

    .top_speed_filter_container {
        color: #636366;
        padding: 4px 12px 4px 12px;
        border-radius: 20px;
        border: 0.5px solid #3C3C435C;
        margin-right: 16px;

        &.active {
            background-color: #007AFF;
            color: #FFFFFF;
            border: 1px solid #007aff;
        }

    }
}

// .playbackBar {
//     -webkit-appearance: none;
//     margin-right: 16px;
//     margin-left: 16px;
//     width: 240px;
//     height: 7px;
//     background: rgba(255, 255, 255, 0.6);
//     border-radius: 5px;
//     background-image: linear-gradient(#fff, #fff);
//     background-size: 100% 100%;
//     background-repeat: no-repeat;

//     &::-webkit-slider-runnable-track:after {
//         content: "";
//         height: 10px;
//         background: #C7C7CC;
//         /* Light gray color after the thumb */
//         border-radius: 5px;
//     }
// }

// .playbackBar::-webkit-slider-thumb {
//     -webkit-appearance: none;
//     height: 20px;
//     width: 20px;
//     border-radius: 50%;
//     background: #000;
//     cursor: ew-resize;
//     box-shadow: 0 0 2px 0 #555;
//     transition: background .3s ease-in-out;
//     cursor: pointer;
// }

// .playbackBar::-webkit-slider-runnable-track {
//     -webkit-appearance: none;
//     box-shadow: none;
//     border: none;
//     background: transparent;
// }


@media screen and (max-width: 1240px) {
    .audio_player_wrapper .audio_player_container .progressBar {
        width: 512px;
    }

    .button_wrapper .button_container .pause_play_container {
        width: 200px;
    }
}

@media screen and (max-width: 750px) {
    .audio_player_wrapper {
        padding-left: 24px;
        padding-right: 24px;
    }

    .audio_player_wrapper .audio_player_container .progressBar {
        width: 240px;
    }

    .button_wrapper .button_container .pause_play_container {
        width: 160px;
    }

    .playbackBar {
        width: 254px;
    }

    .playback_speed_options_container .top_speed_filter_container {
        margin-right: 8px;
    }

}

@media screen and (max-width: 850px) {
    .audio_player_wrapper {
        .audio_player_whole_container {

            &.explore {
                width: 53%;
            }

        }

        .explore_more_container {
            width: 47%;
            right: -47%;
            overflow-y: auto;
            max-height: 100%;

            .mindfulness_session_group_container {
                padding: 16px 24px;

                .audio_session_list_container {
                    padding: 8px 0px;

                    .content_ul_list {
                        .content_list {
                            padding: 8px 16px 8px 0px;
                            margin-bottom: 16px;

                            .session_wrapper {
                                &.explore_more {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            .scroll_button {
                right: 170px;
            }
        }
    }

    .audio_new_container {
        &.minimize {
            width: 100%;
        }

        .audio_title_container .audio_title {
            padding-bottom: 56px;
        }
    }

    .pause_play_container_mini {
        margin-left: 24px;
    }

    .speed_value_container {
        &.opened {
            left: 38%;
        }
    }

    .maximize_button {
        margin-right: 24px;
    }

    .volume_control_wrapper {
        height: 88px;
    }

    .audio_player_wrapper .audio_player_whole_container .audio_player_top_container {
        margin-bottom: 100px;

        .explore_more_title_container {
            width: 148px;
            border-radius: 68px;
            height: 32px;

            .explore_more_text {
                font-weight: 400;
                font-size: 13px;
                line-height: 20;
            }
        }
    }

    .audio_player_wrapper .audio_player_container .progressBar {
        width: 512px !important;

        &.explore {
            width: 240px !important;
        }

        &.opened {
            width: 100% !important;
        }
    }

    .button_wrapper .button_container .pause_play_container {
        width: 160px;
    }

    .playbackBar {
        width: 254px;
    }

    .playback_speed_options_container .top_speed_filter_container {
        margin-right: 8px;
    }
}

@media screen and (max-width: 700px) {
    .audio_player_wrapper {
        padding-left: 0px;
        padding-right: 0px;
        flex-direction: column;
        justify-content: space-between;

        .audio_player_whole_container {
            padding: 20px 24px;

            &.explore {
                width: 53%;
                min-height: auto;

                .audio_player_top_container {
                    margin-bottom: 60px;

                    .back_btn_container {
                        padding-bottom: 52px;
                    }
                }
            }
        }

        .explore_more_container {
            height: 41%;
            min-height: auto;

            &.opened {
                right: auto;
                position: relative;
                width: 100%;

                .explore_more_top_container {
                    position: relative;
                    z-index: 5;
                    right: auto;
                    bottom: 0;
                    width: 100%;
                    overflow: hidden;
                }

                // .mindfulness_session_group_container {
                //     margin-top: 80px;
                // }

            }


            .mindfulness_session_group_container {
                position: relative;
                display: inline-grid;
                overflow-x: scroll;
                padding: 16px 8px 0px 24px;

                .audio_session_list_container {
                    overflow-x: auto;

                    .content_ul_list {
                        flex-direction: row;
                        width: max-content;

                        .content_list {
                            width: 250px;
                        }
                    }
                }
            }

            .scroll_button {
                bottom: 43%;
                right: 32px;
                transform: rotate(270deg);
                position: absolute;


                &.closed {
                    display: none;
                }
            }
        }
    }

    .audio_player_wrapper .audio_player_whole_container.explore {
        width: 100%;
    }

    .volume_control_wrapper {
        height: 64px;
    }

    .speed_value_container {
        left: 35%;
    }

    .audio_new_container .audio_title_container .audio_title {
        font-weight: 600;
        font-size: 17px;
        padding-bottom: 32px;
    }

    .audio_player_wrapper .audio_player_whole_container .audio_player_top_container .explore_more_title_container {
        width: 200px;

        .explore_more_icon {
            width: 16px;
            height: 16px;
        }
    }

    .audio_player_wrapper .audio_player_container .progressBar {
        width: 200px;
    }
}

@media screen and (max-width: 460px) {
    // .audio_player_wrapper {
    //     padding-left: 24px;
    //     padding-right: 24px;
    // }

    .audio_player_wrapper .audio_player_container .progressBar {
        width: 240px !important;
    }
}

@media screen and (max-width: 420px) {
    // .audio_player_wrapper {
    //     padding-left: 24px;
    //     padding-right: 24px;
    // }

    .audio_player_wrapper .audio_player_container .progressBar {
        &.explore {
            width: 220px !important;
        }
    }
}

@media screen and (max-width: 390px) {
    // .audio_player_wrapper {
    //     padding-left: 24px;
    //     padding-right: 24px;
    // }

    .audio_player_wrapper .audio_player_container .progressBar {
        width: 160px;
    }
}

@media screen and (max-width: 340px) {
    // .audio_player_wrapper {
    //     padding-left: 16px;
    //     padding-right: 16px;
    // }

    .audio_player_wrapper .audio_player_container .progressBar {
        width: 100px;
    }
}